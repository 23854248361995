import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

/* Components */
import {FontBody24, FontTitle40, fonts} from "../../../../components/fonts";
import {colors} from "../../../../components/colors";
import {images} from "../../../../components/images";
import Button from "../../../../components/button";

/* Middleware */
import {tryPostSupport} from '../middleware/support';

const TextArea = styled.textarea`
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: ${colors.white};
    margin: 40px 0 50px 0;
    border-radius: 30px;
    padding: 30px 40px;
    resize: none;
    font-family: ${fonts.body.fontFamily};
    font-size: 24px;
    line-height: 28px;
    min-height: 260px;
    border: none;
    width: calc(100% - 80px);
    @media screen and (max-width: 460px) {
        padding: 10px 15px;
        width: calc(100% - 30px);
    }
    &:focus {
        outline: 0;
    }
`;
const Section = styled.div`
  margin-top: 40px;
  overflow: visible;
  height: calc(100% - 132px);
`;
const ThankYou = styled(FontBody24)`
    margin-top: 80px;
    text-align: center;
`;

export default function Form(props) {
    const label = props.label;
    const placeholder = props.placeholder;
    const response = props.response;
    const apiURL = props.apiURL;
    const [message, setMessage] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)

    const onChange = e => setMessage(e.target.value);

    const onSubmit = async e => {
        e.preventDefault();
        if(message !== '') {
            setLoading(true);
            await tryPostSupport(apiURL, {"message": "MESSAGE: " + message});
            setSubmitted(true)
        }
    };

    useEffect(() => {
        if(submitted) {
            setMessage("")
            setLoading(false)
            const timeoutId = setTimeout(() => {
                setSubmitted(false)
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [submitted])

    return(
        <Section>
            <FontTitle40>{label}</FontTitle40>
            <form>
                <TextArea
                    value={message}
                    onChange={onChange}
                    placeholder={placeholder}
                    disabled={loading}
                />
                <Button
                    isValid={message !== "" && !loading}
                    clickable={message !== "" && !loading}
                    onClick={onSubmit}
                    label={loading ? "Sending" : "Send"}
                    icon={images.arrowContinue}
                    iconType={"img"}
                    iconSide={"right"}
                    colorButtonValid={colors.buttonActive}
                    colorButtonInvalid={colors.buttonInactive}
                    colorTextValid={colors.black}
                    colorTextInvalid={colors.black}
                    width={"100%"}
                />
            </form>
            {submitted && <ThankYou>{response}</ThankYou>}
        </Section>
    )
}
