import React from 'react';
import styled from 'styled-components';

/* Components */
import {FontSubtitle28} from "../../../components/fonts";

const Intro = styled.div`
  width: calc(100% - 60px);
  max-width: 900px;
  margin: 0 auto;
  padding: 0 30px; 
  display:flex;
  flex-direction:column;
  gap: 10px;
  text-align: center;
  @media screen and (max-width: 1088px) {
    padding: 0;
    width: 100%;
    & > div {
      font-size: 20px;
    }
    margin-bottom: -10px;
  }
`;
const AllMatches = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`;

export default function Refer() {

    return (
        <AllMatches>
            <Intro>
                <FontSubtitle28 $spaced={true}>COMING SOON!</FontSubtitle28>
            </Intro>
        </AllMatches>
    );
};