import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import styled from 'styled-components';

/* Components */
import { DotButton, useDotButton } from './carouselembladotbutton'
// import { PrevButton, NextButton, usePrevNextButtons } from './carouselemblaarrowbuttons'

/* Containers */
import Match from './match';

const Embla = styled.section`
    width: 100%;
    @media screen and (max-width: 1088px) {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;
const Viewport = styled.div`
    width: 100%;
    @media screen and (max-width: 1088px) {
        overflow: hidden;
        flex-grow: 1;
    }
`;
const Container = styled.div`
    @media screen and (min-width: 1089px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 30px;
        padding: 0 30px;
        width: calc(100% - 60px - 6px);
    }
    @media screen and (max-width: 1088px) {
        display: flex;
        touch-action: pan-y pinch-zoom;
        margin-left: calc(30px * -1);
        padding: 10px 0;
    }
`;
const Slide = styled.div`
    @media screen and (min-width: 1088px) {
        max-width: 400px;
        width: calc(33% - 1px);
    }
    @media screen and (max-width: 1088px) {
        transform: translate3d(0, 0, 0);
        flex: 0 0 70%;
        min-width: 0;
        padding-left: 30px;
   }
`;
const Item = styled.div`
    user-select: none;
    border-radius: 20px;
    box-shadow: 0 3px 10px 0 rgb(0, 0, 0, 0.25);
`;
const Controls = styled.div`
    display: none;
    @media screen and (max-width: 1088px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      //justify-content: space-between;
      align-items: center;
    }
`;
// const Buttons = styled.div`
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     gap: 10px;
//     align-items: center;
// `;
const Dots = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
`;

export default function EmblaCarousel(props) {
    const { slides } = props
    const [emblaRef, emblaApi] = useEmblaCarousel({
        breakpoints: {
            '(max-width: 1088px)': {active: true, loop: true},
            '(min-width: 1089px)': {active: false, loop: false}
        }
    })

    const { selectedIndex, scrollSnaps, onDotButtonClick } =
        useDotButton(emblaApi)

    // const {
    //     prevBtnDisabled,
    //     nextBtnDisabled,
    //     onPrevButtonClick,
    //     onNextButtonClick
    // } = usePrevNextButtons(emblaApi)

    return (
        <Embla>
            <Viewport ref={emblaRef}>
                <Container>
                    {slides.map((index, sIndex) => (
                        <Slide key={sIndex}>
                            <Item><Match a={index} /></Item>
                        </Slide>
                    ))}
                </Container>
            </Viewport>
            <Controls>
                {/*<Buttons>*/}
                {/*    <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />*/}
                {/*    <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />*/}
                {/*</Buttons>*/}
                <Dots>
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            onClick={() => onDotButtonClick(index)}
                            $isSelected={index === selectedIndex}
                        />
                    ))}
                </Dots>
            </Controls>
        </Embla>
    )
}