import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* Components */
import {Title, Subtitle, Input, InputDiv, Inputs} from '../components/q'
import {FontHeader48, FontSubtitle20} from "../../../components/fonts";

export default function QText(props) {
    const a = props.a;
    const q = props.q;
    const icon = props.i.icon;
    const title = props.i.title;
    const subtitle = props.i.subtitle;
    const dataKey = props.i.dataKey;
    const onAnswer = props.onAnswer;
    const color = props.color;
    const [isNew, setIsNew] = useState(true);
    const [full, setFull] = useState({"first": "", "last": ""});
    const inputRef = useRef(null);

    useEffect(() => {
        // console.log(a["q"+q]?.[dataKey])
        if(q > 1 || a["q"+q] !== undefined) {
            inputRef.current.focus();
        }
    }, [])

    const onChange = (e, field) => {
        e.preventDefault();
        const value = e.target.value;
        const isValidCheck = value.replace(/\s/g, '').length > 0;
        const fullName = {
            "first": field === "first" ? value : (a["q"+q]?.[dataKey]?.first === undefined ? full.first : a["q"+q]?.[dataKey]?.first),
            "last": field === "last" ? value : (a["q"+q]?.[dataKey]?.last === undefined ? full.last : a["q"+q]?.[dataKey]?.last)
        }
        setFull(fullName)
        const isValid = {
            "first": field === "first" ? isValidCheck : (a["q"+q]?.[dataKey]?.first === undefined ? full.first.replace(/\s/g, '').length > 0 : a["q"+q]?.[dataKey]?.first?.replace(/\s/g, '').length > 0),
            "last": field === "last" ? isValidCheck : (a["q"+q]?.[dataKey]?.last === undefined ? full.last.replace(/\s/g, '').length > 0 : a["q"+q]?.[dataKey]?.last?.replace(/\s/g, '').length > 0)
        }
        onAnswer({["q"+q]: {[dataKey]: fullName, "isValid": isValid.first && isValid.last}})
        if(isNew) {
            setIsNew(false);
        }
    }

    return(
        <>
            <InputDiv>
                {/*<FontAwesomeIcon*/}
                {/*    icon={icon}*/}
                {/*    style={{"color": color, fontSize: '30px'}}*/}
                {/*/>*/}
                <FontSubtitle20 style={{
                    marginBottom: '20px',
                }} $spaced={true}>{subtitle}</FontSubtitle20>
                <FontHeader48
                >{title}</FontHeader48>
                <Subtitle></Subtitle>
                <Inputs>
                    <Input
                        ref={inputRef}
                        type={"text"}
                        defaultValue={a["q"+q]?.[dataKey]?.first === undefined ? "" : a["q"+q]?.[dataKey].first}
                        onChange={(e) => {onChange(e, "first")}}
                        $isValid={isNew ? true : a["q"+q]?.[dataKey]?.first?.replace(/\s/g, '').length > 0}
                        name={"fname"}
                        autoComplete={"given-name"}
                        placeholder={"First"}
                    />
                    <Input
                        type={"text"}
                        defaultValue={a["q"+q]?.[dataKey]?.last === undefined ? "" : a["q"+q]?.[dataKey].last}
                        onChange={(e) => {onChange(e, "last")}}
                        $isValid={isNew ? true : a["q"+q]?.[dataKey]?.last?.replace(/\s/g, '').length > 0}
                        name={"lname"}
                        autoComplete={"family-name"}
                        placeholder={"Last"}
                    />
                </Inputs>
            </InputDiv>
        </>
    )
}