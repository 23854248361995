import React from 'react';
import styled from "styled-components";

/* Components */
import {FontBody24, FontTitle40} from "../../../../components/fonts";
import {colors} from "../../../../components/colors";
import {images} from "../../../../components/images";

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 52px;
  gap: 20px;
`;
const Back = styled.div`
  width: fit-content;
  color: ${colors.primary100};
  cursor: pointer;
`;
const CloseIcon = styled.img`
  width: 26px;
  height: 29px;
  cursor: pointer;
`;
export const ActionBarLeftBar = ({supportPage, setPage, closeSupport}) => (
    <Top>
        <div>
            {supportPage !== 1
                ? <FontBody24>
                    <Back onClick={setPage}>
                        <img src={images.chevronLeft} alt={"<"} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back
                    </Back>
                </FontBody24>
                : <FontTitle40>How can we help?</FontTitle40>
            }
        </div>
        <div onClick={closeSupport} >
            <CloseIcon src={images.firmXIcon} alt={"X"} />
        </div>
    </Top>
)

const LeftBarSelection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid ${colors.background100};
  padding: 26px 0;
  margin-right: 50px;
  justify-content: flex-start;
  align-items: center;
  font-size: 28px;
  cursor: pointer;
`;
const LeftBarIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const SelectionModal = ({onClick, icon, label}) => {
    return(
        <LeftBarSelection onClick={onClick}>
            <LeftBarIcon src={icon} alt={""}/>
            <FontBody24>{label}</FontBody24>
        </LeftBarSelection>
    )
}