import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useSelector} from "react-redux";

/* Middleware */
import {tryPostGetMatches} from "./middleware/getmatches";

/* Components */
import {FontBody16, FontSubtitle28} from "../../../components/fonts";
import {LogoLoading} from "../../../components/loading";

/* Containers */
import CarouselEmbla from "./containers/carouselembla";

const Intro = styled.div`
  width: calc(100% - 60px);
  max-width: 900px;
  margin: 0 auto;
  padding: 0 30px; 
  display:flex;
  flex-direction:column;
  gap: 10px;
  text-align: center;
  @media screen and (max-width: 1088px) {
    padding: 0;
    width: 100%;
    & > div {
      font-size: 20px;
    }
    margin-bottom: -10px;
  }
`;
const AllMatches = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //width: calc(100% - 60px);
  //padding: 0 30px;
  gap: 40px;
  //flex-grow: 1;
  //flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1088px) {
    //max-width: 600px;
  }
`;
const NoRecords = styled(FontBody16)`
  margin: 0 auto;
  width: calc(100% - 60px);
  padding: 0 30px;
`;

export default function Advisors() {
    const [allMatches, setAllMatches] = useState(null);
    const t1Store = useSelector(state => state.session.t1);
    const matchedStore = useSelector(state => state.session.matched);

    useEffect(() => {
        if(t1Store !== null && matchedStore) {
            init()
        }
    }, [t1Store])

    const init = async () => {
        const matchData = await tryPostGetMatches({t1: t1Store, cache: true});
        setAllMatches(matchData.data.reduce((filtered, a) => {
            let photos = a.additionalProfileInformation.photos.filter(p => { return (p !== null) })
            let logos = a.additionalProfileInformation.logos.filter(l => { return (l !== null) })
            let date = a.badActorDate;
            let advisorRecord = {
                ...a,
                ...a.additionalProfileInformation,
                photos: photos.length > 0 ? photos[0] : null,
                logos: logos.length > 0 ? logos[0] : null,
                priority: false, // is sponsored advisor
                active: true, // is advisor active (expiration not hit)
                minAssets: parseInt(a.minimumInvestableAssets),
                gr: a.gender,
            };
            if (date) {
                // bad actor
            } else {
                filtered.push(advisorRecord)
            }
            return filtered
        }, []))
    }

    if (allMatches === null) {
        return (<LogoLoading pad={60} />)
    }

    return (
        <AllMatches>
            <Intro>
                <FontSubtitle28 $spaced={true}>Review Your Advisor Matches</FontSubtitle28>
                {/*<FontBody16>Please review each advisor’s profile before selecting your top choice. Once you select an advisor, a member of the Willow team will reach out to review your choice before giving your information to the advisor.</FontBody16>*/}
            </Intro>
            {allMatches.length === 0
                ? <NoRecords>No advisors found</NoRecords>
                : <CarouselEmbla slides={allMatches} />
            }
        </AllMatches>
    );
};