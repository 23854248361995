import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {FontHeader48, fonts, FontSubtitle20} from "./fonts";

const Btn = styled.button`
    gap: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 16px 32px;
    &:focus-visible {
        outline: none;
    }
    ${props => props.$width === undefined && css`
        width: fit-content;
    `}
    ${props => props.$width !== undefined && css`
        width: ${props => props.$width};
        //max-width: calc(${props => props.$width} - 64px);
        max-width: calc(${props => props.$width} - 0px);
    `}
    margin: 0;
    border-style: solid;
    border-radius: 20px;
    ${props => props.$clickable && css`
        background-color: ${props => props.$colorButtonValid};
        border-color: ${props => props.$colorButtonValid};
        cursor: pointer;
        color: ${props => props.$colorTextValid};
    `}
    ${props => !props.$clickable && css`
        background-color: ${props => props.$colorButtonInvalid};
        border-color: ${props => props.$colorButtonInvalid};
        cursor: default;
        color: ${props => props.$colorTextInvalid};
    `}
`;

export default function Button(props) {
    const clickable = props.clickable;
    const onClick = props.onClick;
    const label = props.label;
    const icon = props.icon;
    const iconType = props.iconType;
    const iconSide = props.iconSide;
    const isValid = props.isValid;
    const colorButtonValid = props.colorButtonValid;
    const colorButtonInvalid = props.colorButtonInvalid;
    const colorTextValid = props.colorTextValid;
    const colorTextInvalid = props.colorTextInvalid;
    const width = props.width;

    return (
        <Btn
            onClick={onClick}
            $clickable={clickable}
            $colorButtonValid={colorButtonValid}
            $colorButtonInvalid={colorButtonInvalid}
            $colorTextValid={colorTextValid}
            $colorTextInvalid={colorTextInvalid}
            $width={width}
            disabled={!clickable}
        >
            {iconSide === "left" &&
                <>{iconType === "fa"
                    ? <FontAwesomeIcon
                        icon={icon}
                        style={{"color": isValid ? colorTextValid : colorTextInvalid, fontSize: '16px'}}
                    />
                    : <img src={icon} alt={""} />
                }</>
            }
          <FontSubtitle20 $spaced={true}> {label}</FontSubtitle20>
            {iconSide === "right" &&
                <>{iconType === "fa"
                    ? <FontAwesomeIcon
                        icon={icon}
                        style={{"color": isValid ? colorTextValid : colorTextInvalid, fontSize: '16px'}}
                    />
                    : <img src={icon} style={{height:"20px"}} alt={""} />
                }</>
            }
        </Btn>
    );
};