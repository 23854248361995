import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from "styled-components";
import { Range, getTrackBackground } from "react-range";

/* Components */
import {Subtitle, Title, SubtitleSlider} from '../components/q'

import {FontBody18, FontHeader18, FontBody14, FontSubtitle20, FontHeader48} from "../../../components/fonts";
import { colors } from "../../../components/colors";

const ResultLabel = styled(FontBody18)`
    margin-top: 20px;
    color: ${colors.white};
`;
const ResultDollars = styled(FontHeader18)`
    font-size: 24px;
    margin-bottom: 20px;
    color: ${colors.action100};
    font-weight: bold;
`;
const Row = styled(FontBody14)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const RangeContainer = styled.div`
    width: calc(100% - 64px);
    max-width: 800px;
    margin-top: 30px;
    #thumb {
      outline: none;
    }
`;

const styleRange = {
    height: "36px",
    display: "flex",
    width: "100%"
}
const styleTrack = (min, max, assets) => ({
    height: "14px",
    width: "100%",
    borderRadius: "50px",
    background: getTrackBackground({
        values: [assets],
        colors: [colors.action100, colors.background100],
        min: min,
        max: max
    }),
    alignSelf: "center"
})
const styleThumb = {
    height: "50px",
    width: "50px",
    borderRadius: "100%",
    backgroundColor: colors.action100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)"
}
const styleThumbDragged = (isDragged) => ({
    // height: "16px",
    // width: "5px",
    // backgroundColor: isDragged ? "#548BF4" : "#CCC"
})

export default function QAssets(props) {
    const a = props.a;
    const q = props.q;
    const icon = props.i.icon;
    const title = props.i.title;
    const subtitle = props.i.subtitle;
    const min = props.i.min;
    const max = props.i.max;
    const step = props.i.step;
    const initValue = props.i.initValue;
    const dataKey = props.i.dataKey;
    const dataKey2 = props.i.dataKey2;
    const dataKeyQuestion = props.i.dataKeyQuestion;
    const maxLabel = props.i.maxLabel;
    const minLabel = props.i.minLabel;
    const onAnswer = props.onAnswer;
    const color = props.color;
    const [assets, setAssets] = useState(initValue);
    const inputHiddenRef = useRef(null);

    const calcRoptRange = (value) => {
        // if(value <= 100000) {
        //     return(1)
        // } else if(value < 250000) {
        //     return(2)
        // } else if(value < 500000) {
        //     return(3)
        // } else if(value < 1000000) {
        //     return(4)
        // } else if(value < 5000000) {
        //     return(5)
        // } else {
        //     return(6)
        // }
        return(value) // moved the range assessment to the back-end, passing actual value
    }

    useEffect(() => {
        if(a["q"+q] === undefined) {
            onAnswer({["q"+q]: {[dataKey]: calcRoptRange(initValue), "assets": initValue, "isValid": true, "dataKey2": dataKey2, "dataKeyQuestion": dataKeyQuestion}})
        } else {
            // if(q.dataKey2 === a["q"+q].dataKey2) {
                setAssets(a["q"+q]?.assets);
            // } else {
            //
            // }
        }
        inputHiddenRef.current.focus();
    }, []);

    const onChange = (value) => {
        setAssets(value[0]);
        onAnswer({["q"+q]: {[dataKey]: calcRoptRange(value[0]), "assets": value[0], "isValid": true, "dataKey2": dataKey2, "dataKeyQuestion": dataKeyQuestion}})
    }

    const calcApproxValue = () => {
        let val
        if(assets === min) {
            val = minLabel
        } else if(assets === max) {
            val = maxLabel
        } else {
            if(assets < 1000000) {
                val = "$" + Math.round(assets/1000).toString() + "k"
            } else {
                val = "$" + (assets/1000000).toFixed(1).toString() + "m"
            }
        }
        return(val)
    }

    return(
        <>
            {/*<FontAwesomeIcon*/}
            {/*    icon={icon}*/}
            {/*    style={{"color": color, fontSize: '30px'}}*/}
            {/*/>*/}

            <FontHeader48>{title}</FontHeader48>
            {subtitle !== null && <SubtitleSlider>{subtitle}</SubtitleSlider>}
            <ResultLabel>Approximately</ResultLabel>
            <ResultDollars>{calcApproxValue()}</ResultDollars>
            <RangeContainer>
                <Range
                    type={"range"}
                    min={min}
                    max={max}
                    name={"assets"}
                    step={step}
                    values={[assets]}
                    onChange={onChange}
                    renderTrack={({ props, children }) => (
                        <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={{...props.style, ...styleRange}}>
                            <div ref={props.ref} style={styleTrack(min, max, assets)}>{children}</div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div id={"thumb"} {...props} style={{...props.style, ...styleThumb}}>
                            <div style={styleThumbDragged(isDragged)}/>
                        </div>
                    )}
                />
            </RangeContainer>
            <Row>
                <label htmlFor={"assets"}>{minLabel}</label>
                <label htmlFor={"assets"}>{maxLabel}</label>
            </Row>
            <input
                ref={inputHiddenRef}
                type={"text"}
                value={""}
                onChange={() => {}}
                style={{"opacity": "0", "height": "1px", "cursor": "default"}}
            />
        </>
    )
}