export const colors = {

    alert100: '#FF9180',

    action100: '#FFB930', //'#D6B88B', // canvaGold
    action70: '#FFDC97', //'#e4d1b4',

    primary100: '#394950', // canvaDarkBlue
    primary70: '#748386', // canvaDarkGreen
    primary30: '#959CA3',

    secondary100: '#777A69',

    background100: '#d7d7d7',
    background30: '#E9ECEF',
    background20: '#F2F4F5',
    background15: '#F8F9FA',

    white: '#FFFFFF',
    black: '#000000',

    canvaGold: '#D6B88B',
    canvaBrown: '#c1baaf',
    canvaSand: '#ece5da',
    canvaBlush: '#eaddd9',
    canvaGreen: '#8d9f97',
    canvaBlue: '#3f5873',
    canvaGray: '#e9e9e9',

    green: '#5BC7B4',
    buttonActive: '#FFB930',
    buttonInactive: '#FFDC97',
    footer: '#394950',
    footerText: '#DADADA'

}