import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {DateTime} from 'luxon';

/* Components */
import { colors } from '../../../components/colors'

/* Components */
import {Title, Subtitle, Boxes, Box, IconTop, IconBtns, OptionTitle, InputDiv} from '../components/q'
import {FontHeader48, FontSubtitle20} from "../../../components/fonts";

export default function QRadio(props) {
    const a = props.a;
    const q = props.q;
    const icon = props.i.icon;
    const title = props.i.title;
    const options = props.i.options;
    const subtitle = props.i.subtitle;
    const dataKey = props.i.dataKey;
    const dataKey2 = props.i.dataKey2;
    const dataKeyQuestion = props.i.dataKeyQuestion;
    const onAnswer = props.onAnswer;
    const color = props.color;
    const slim = props.i.slim;
    const [isSelected, setIsSelected] = useState(null);


    useEffect(() => {
        if(isSelected === null) {
            setIsSelected(a["q"+q.num]?.[dataKey])
        }
    }, [])

    const onChange = async (title, inject) => {
        setIsSelected(isSelected === title ? null : title)
        const isValid = true; //isSelected === title ? false : title !== null; turned off along with removal of continue button
        const onAnswerRecord = {["q"+q.num]: {[dataKey]: title, "isValid": isValid, "dataKey2": dataKey2, "dataKeyQuestion": dataKeyQuestion, "isRadio": true, "refresh": DateTime.now().toISO()}};
        if (inject !== null && inject[0].dataKey !== q.allQuestions[q.num]?.dataKey) {
            await props.onFork(inject, q.num, onAnswerRecord)
        } else {
            onAnswer(onAnswerRecord)
        }
    }


    return(
        <InputDiv>
            {/*{icon !== null &&*/}
            {/*    <IconTop>*/}
            {/*        <FontAwesomeIcon*/}
            {/*            icon={icon}*/}
            {/*            style={{"color": color, fontSize: '30px'}}*/}
            {/*        />*/}
            {/*    </IconTop>*/}
            {/*}*/}
            <FontSubtitle20 style={{
                marginBottom: '20px',
            }} $spaced={true}>{subtitle}</FontSubtitle20>
            <FontHeader48
            >{title}</FontHeader48>
            <Subtitle></Subtitle>
            <Boxes $slim={slim}>
                {options.map((o, oIndex) => (
                    <Box
                        key={oIndex}
                        onClick={() => {onChange(o.title, o.inject)}}
                        $selected={isSelected === o.title}
                        $color={color}
                        $slim={slim}
                        $isMoreBtn={false}
                    >
                        {o.icon !== null &&
                            <IconBtns>
                                <FontAwesomeIcon
                                    icon={o.icon}
                                    style={{"color": (isSelected === o.title ? colors.white : color), fontSize: '30px'}}
                                />
                            </IconBtns>
                        }
                        <OptionTitle>{o.title}</OptionTitle>
                    </Box>
                ))}
            </Boxes>
        </InputDiv>
    )
}