import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import validator from "validator";
import Cleave from "cleave.js/dist/cleave-react-node";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import styled from 'styled-components';

/* Components */
import { Title, Subtitle, CleaveContainer, InputDiv } from '../components/q'
import { colors } from "../../../components/colors";
import {FontBody14, FontHeader48, fonts, FontSubtitle20} from "../../../components/fonts";
import Button from "../../../components/button";
import {images} from "../../../components/images";
import {ButtonContainer} from '../components/q';

const inputsStyles = (isValid) => ({
    "padding": "16px 32px",
    "width": "calc(100% - 64px)",
    "maxWidth": "calc(380px - 128px)",
    "fontSize": "24px",
    "textAlign": "center",
    "fontFamily": fonts.body.fontFamily,
    "border": `2px solid ${isValid ? colors.background30 : colors.alert100}`,
});
const Center = styled.div`
  & > div {
    margin: 0 auto;
  }
`;
const TextCenter = styled.div`
  text-align: center;

`;
const Parent = styled.div`
  width: 100%;
`;
const Disclosure = styled(FontBody14)`
  max-width: 600px;
  text-align: center;
  color: ${colors.background100};
  line-height: 18px;
  & a {
    color: ${colors.background100};
    text-decoration: underline;
  }
`;

export default function QPhone(props) {
    const a = props.a;
    const q = props.q;
    const icon = props.i.icon;
    const title = props.i.title;
    const subtitle = props.i.subtitle;
    const dataKey = props.i.dataKey;
    const dataKey2 = props.i.dataKey2;
    const inputName = props.i.inputName;
    const inputAutocomplete = props.i.inputAutocomplete;
    const dataKeyQuestion = props.i.dataKeyQuestion;
    const onAnswer = props.onAnswer;
    const onForward = props.onForward;
    const color = props.color;
    const [isNew, setIsNew] = useState(true);
    // const [isChecked, setIsChecked] = useState(false);
    let inputRef;

    useEffect(() => {
        if(q > 1 || a["q"+q] !== undefined) {
            inputRef.focus();
        }
    }, [])

    const onChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        const isValid = validator.isMobilePhone(value, 'en-US')
        onAnswer({["q"+q]: {[dataKey]: value, "isValid": isValid, "dataKey2": dataKey2, "dataKeyQuestion": dataKeyQuestion}})
        if(isNew) {
            setIsNew(false);
        }
    }

    function findKey(obj, key) {
        for (const prop in obj) {
            if (prop === key) {
                return obj[prop];
            } else if (typeof obj[prop] === 'object') {
                const result = findKey(obj[prop], key);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    return(
        <InputDiv>
            {/*<FontAwesomeIcon*/}
            {/*    icon={icon}*/}
            {/*    style={{"color": color, fontSize: '30px'}}*/}
            {/*/>*/}
            <TextCenter>
                <FontSubtitle20 style={{
                    marginBottom: '20px',
                }} $spaced={true}>{subtitle}</FontSubtitle20>
                <FontHeader48>{title}</FontHeader48>
                <Subtitle></Subtitle>
            </TextCenter>

            <MailchimpSubscribe
                url={"https://trustwillow.us4.list-manage.com/subscribe/post?u=f4204eb4c8be40e72dd4a51cf&amp;id=73ee9c57f1&amp;f_id=00436eeaf0"}
                render={({ subscribe, status, message }) => (
                    <Parent>
                        <CleaveContainer $isValid={isNew ? true : a["q"+q]?.isValid}>
                            <Cleave
                                htmlRef={(ref) => inputRef = ref}
                                options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                                onChange={onChange}
                                placeholder={"(###) ###-####"}
                                value={a["q"+q]?.[dataKey]}
                                style={inputsStyles(isNew ? true : a["q"+q]?.isValid)}
                                name={inputName}
                                autoComplete={inputAutocomplete}
                            />
                        </CleaveContainer>
                        {/*<div>*/}
                            {/*<input*/}
                            {/*    type={"checkbox"}*/}
                            {/*    name={"mc-SMSPHONE-ack"}*/}
                            {/*    checked={isChecked}*/}
                            {/*    onChange={() => {setIsChecked(!isChecked)}}*/}
                            {/*/>*/}
                            {/*<div>*/}
                            {/*    Willow - By providing your phone number and checking the box, you agree to receive promotional and marketing messages,*/}
                            {/*    notifications, and customer service communications from Willow. Message and data rates may apply. Consent is not a*/}
                            {/*    condition of purchase. Message frequency varies. Text HELP for help. Text STOP to cancel. See  and*/}
                            {/*    <a href={"http://eepurl.com/iWf7VU"} target={"_blank"}>Terms</a>.*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        <br />
                        <br />
                        <Center>
                            <ButtonContainer>
                                <Button
                                    isValid={a["q"+q]?.isValid/* && isChecked*/}
                                    clickable={(a["q"+q]?.isValid/* && isChecked*/) && status !== "sending"}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        if(!window.location.href.includes("localhost")) {
                                            await subscribe({
                                                "EMAIL": findKey(a, "email"),
                                                "SMSPHONE": a["q"+q]?.[dataKey],
                                                "mc-SMSPHONE-ack": "true",
                                            })
                                        }
                                        onForward(null);
                                    }}
                                    label={status === "sending" ? "MATCHING!" : "VIEW MATCHES"}
                                    icon={images.arrowContinue}
                                    iconType={"img"}
                                    iconSide={"right"}
                                    colorButtonValid={colors.buttonActive}
                                    colorButtonInvalid={colors.buttonInactive}
                                    colorTextValid={colors.black}
                                    colorTextInvalid={colors.black}
                                    width={"100%"}
                                />
                            </ButtonContainer>
                            <br />
                            <Disclosure>
                                By providing your phone number, you agree to receive a call from Willow's customer
                                service team to verify your info and help connect you with an advisor you matched with.
                                You may receive marketing message reminders to complete your match. Once matched, you
                                will receive notifications to ensure your advisor continues to be suitable.
                                Message frequency varies. For more information, see the
                                <a href={"http://eepurl.com/iWf7VU"} target={"_blank"}> terms</a>.
                            </Disclosure>
                        </Center>
                    </Parent>
                )}
            />
        </InputDiv>
    )
}