import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        t1: null,
        t2: null,
        matched: false,
        ropt: null,
        st: null,
        utm_campaign: null,
        utm_source: null,
        utm_content: null,
        utm_term:null,
        utm_medium:null,
        support_page: null
    },
    reducers: {
        setT1: (state, action) => {
            state.t1 = action.payload.t1
        },
        setT2: (state, action) => {
            state.t2 = action.payload.t2
        },
        setMatched: (state, action) => {
            state.matched = action.payload.matched
        },
        setRopt: (state, action) => {
            state.ropt = action.payload.ropt
        },
        setSt: (state, action) => {
            state.st = action.payload.st
        },
        setCampaign: (state, action) => {
            state.utm_campaign = action.payload.utm_campaign
        },
        setSource: (state, action) => {
            state.utm_source = action.payload.utm_source
        },
        setContent: (state, action) => {
            state.utm_content = action.payload.utm_content
        },
        setTerm: (state, action) => {
            state.utm_term = action.payload.utm_term
        },
        setMedium: (state, action) => {
            state.utm_medium = action.payload.utm_medium
        },
        setSupportOpen: (state, action) => {
            state.support_page = action.payload.support_page
        },
    }
});

export const {setT1, setT2, setMatched, setRopt, setSt, setCampaign, setContent, setMedium, setSource, setTerm, setSupportOpen } = sessionSlice.actions;

export default sessionSlice.reducer