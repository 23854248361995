import {axiosReq} from "../../../../utils/axios";

const devData = {data: [
        {
            "id": 1120,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "interests",
            "event_answer": "{\"capturedInterests\":\"Reading\"}",
            "event_date": "2025-01-22T20:49:05.464Z",
            "event_source": "willow"
        },
    {
        "id": 1121,
        "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
        "event_name": "surveyNeedPartnerFor",
        "event_answer": "{\"capturedSurveyneedpartnerfor\":\"Financial Planning, Investing\"}",
        "event_date": "2025-01-22T20:49:05.504Z",
        "event_source": "willow"
    },
        {
            "id": 1122,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveyLifeEvents",
            "event_answer": "{\"capturedSurveylifeevents\":\"Retiring\"}",
            "event_date": "2025-01-22T20:49:05.605Z",
            "event_source": "willow"
        },
        {
            "id": 1123,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveySpecialConsiderations",
            "event_answer": "{\"capturedSurveyspecialconsiderations\":\"Underrepresented group\"}",
            "event_date": "2025-01-22T20:49:05.767Z",
            "event_source": "willow"
        },
        {
            "id": 1124,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "maritalStatus",
            "event_answer": "{\"capturedMaritalStatus\":\"Relationship\"}",
            "event_date": "2025-01-22T20:49:06.980Z",
            "event_source": "willow"
        },
        {
            "id": 1125,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveyProfession",
            "event_answer": "{}",
            "event_date": "2025-01-22T20:49:07.088Z",
            "event_source": "willow"
        },
        {
            "id": 1126,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveyProfession",
            "event_answer": "{}",
            "event_date": "2025-01-22T20:49:07.187Z",
            "event_source": "willow"
        },
        {
            "id": 1127,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveyYearsToRetire",
            "event_answer": "{\"capturedSurveyyearstoretire\":\"5-10 Years\"}",
            "event_date": "2025-01-22T20:49:07.353Z",
            "event_source": "willow"
        },
        {
            "id": 1128,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveyIncome",
            "event_answer": "{\"capturedSurveyincome\":\"150000\"}",
            "event_date": "2025-01-22T20:49:07.459Z",
            "event_source": "willow"
        },
        {
            "id": 1129,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "businessOwn",
            "event_answer": "{\"capturedBusinessOwn\":\"Yes\"}",
            "event_date": "2025-01-22T20:49:07.560Z",
            "event_source": "willow"
        },
        {
            "id": 1130,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "advisorType",
            "event_answer": "{\"capturedAdvisorType\":\"Logical\"}",
            "event_date": "2025-01-22T20:49:07.616Z",
            "event_source": "willow"
        },
        {
            "id": 1131,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "workingWithAdvisor",
            "event_answer": "{\"capturedWorkingWithAdvisor\":\"Yes\"}",
            "event_date": "2025-01-22T20:49:07.692Z",
            "event_source": "willow"
        },
        {
            "id": 1132,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveyFrequencyOfCommunications",
            "event_answer": "{\"capturedSurveyfrequencyofcommunications\":\"Quarterly\"}",
            "event_date": "2025-01-22T20:49:07.827Z",
            "event_source": "willow"
        },
        {
            "id": 1133,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "finGoals",
            "event_answer": "{\"capturedFinGoals\":\"Create a Budget\"}",
            "event_date": "2025-01-22T20:49:07.924Z",
            "event_source": "willow"
        },
        {
            "id": 1134,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveyEmployerRetirementAccounts",
            "event_answer": "{\"capturedSurveyemployerretirementaccounts\":\"250000\"}",
            "event_date": "2025-01-22T20:49:08.017Z",
            "event_source": "willow"
        },
        {
            "id": 1135,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveyEmployerRetirementAccounts",
            "event_answer": "{\"capturedSurveyemployerretirementaccounts\":\"250000\"}",
            "event_date": "2025-01-22T20:49:08.151Z",
            "event_source": "willow"
        },
        {
            "id": 1136,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveyOtherRetirementAccounts",
            "event_answer": "{\"capturedSurveyotherretirementaccounts\":\"500000\"}",
            "event_date": "2025-01-22T20:49:08.253Z",
            "event_source": "willow"
        },
        {
            "id": 1137,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveyOtherRetirementAccounts",
            "event_answer": "{\"capturedSurveyotherretirementaccounts\":\"500000\"}",
            "event_date": "2025-01-22T20:49:08.372Z",
            "event_source": "willow"
        },
        {
            "id": 1138,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "rangeAssets",
            "event_answer": "{\"minAssets\":\"0\",\"assetsRange\":\"$0-$250K\",\"assetsRangeAnswer\":\"250000\",\"actualAssetsAnswer\":\"250000\"}",
            "event_date": "2025-01-22T20:49:08.449Z",
            "event_source": "willow"
        },
        {
            "id": 1139,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "rangeAssets",
            "event_answer": "{\"minAssets\":\"0\",\"assetsRange\":\"$0-$250K\",\"assetsRangeAnswer\":\"250000\",\"actualAssetsAnswer\":\"250000\"}",
            "event_date": "2025-01-22T20:49:08.568Z",
            "event_source": "willow"
        },
        {
            "id": 1140,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveyHasDebt",
            "event_answer": "{\"capturedSurveyhasdebt\":\"Yes\"}",
            "event_date": "2025-01-22T20:49:08.677Z",
            "event_source": "willow"
        },
        {
            "id": 1141,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "surveyHomeowner",
            "event_answer": "{\"capturedSurveyhomeowner\":\"Yes\"}",
            "event_date": "2025-01-22T20:49:08.795Z",
            "event_source": "willow"
        },
        {
            "id": 1142,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "referral",
            "event_answer": "{\"capturedReferral\":\"Word of Mouth\"}",
            "event_date": "2025-01-22T20:49:08.888Z",
            "event_source": "willow"
        },
        {
            "id": 1143,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "pets",
            "event_answer": "{\"capturedPets\":\"Bunnies\"}",
            "event_date": "2025-01-22T20:49:09.021Z",
            "event_source": "willow"
        },
        {
            "id": 1144,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "email",
            "event_answer": "{\"capturedEmail\":\"mlvqebjhxlqplrrhvm@hthlm.com\"}",
            "event_date": "2025-01-22T20:49:19.677Z",
            "event_source": "willow"
        },
        {
            "id": 1145,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "phone",
            "event_answer": "{\"capturedPhone\":\"(413) 519-3519\"}",
            "event_date": "2025-01-22T20:49:25.332Z",
            "event_source": "willow"
        }
    ]}

export const tryGetProfile = (data) => {
    return axiosReq('https://reportingapi.trustwillow.com/api/events/anon-id/'+data+'/event-source/willow', "GET", {},  devData);
};
