import React from 'react';

export default [
    {
        "title": "General",
        "list": [
            {
                "title": "How does Willow match me with the right financial advisor for my specific needs and goals?",
                "body": <div>Willow takes a personalized approach to matching. We start by understanding your unique financial situation, goals, and preferences through a brief questionnaire. Then, all of this information is run through our system to connect you with advisors who specialize in areas that align with your needs, whether it’s retirement planning, wealth building, or navigating major life changes. We also take into consideration personality traits you are looking for in a financial advisor. <span data-link-type='page' data-label='Contact your RM' data-label-action-props='2'>Contact your RM</span>.</div>
            },
            {
                "title": "What qualifications and experience do Willow's financial advisors have?",
                "body": <div>Every advisor on Willow's platform goes through Willow’s Advisor for Women™ or Advisor for NextGen™ Certificate Program. Many hold recognized designations as well such as CFP®, CDFA®, or CPA and bring a minimum of 2 years of experience in financial planning and investment management. They are also committed to ongoing education and staying up-to-date with the latest industry practices through Willow’s ongoing requirements.</div>
            },
            {
                "title": "How much does it cost to work with a financial advisor through Willow?",
                "body": <div>Every advisor has their own fee structure that is outlined on their profile. However, fees can vary depending on your unique circumstances so it is always important to ask your advisor. </div>
            },
            {
                "title": "Can I interview or meet with potential advisors before deciding who to work with?",
                "body": <div>Absolutely! Willow encourages you to set up an introductory conversation with your top choice advisor to make sure it is a match! This ensures that you find someone you’re comfortable with and who truly understands your goals. Think of it as a no-pressure “get-to-know-you” session. </div>
            },
            {
                "title": "Are the advisors on Willow’s platform fiduciaries? ",
                "body": <div>Advisor for Women™ and Advisor for NextGen™ Certificate Holders agree to uphold a fiduciary standard of care as part of our client commitment. </div>
            },
            {
                "title": "I don’t think I was given the best advisor matches for me. Can I see new ones? ",
                "body": <div>Yes, you can! Just head back to your profile on your dashboard and click “rematch”! Here you can provide us with a bit more information about what you are looking for in a financial advisor to help us get you connected with a better fit! </div>
            }
        ]
    },
    {
        "title": "Account & Privacy",
        "list": [
            {
                "title": "How does Willow keep information confidential?",
                "body": <div>We take data security seriously. Willow is protected with 256-bit encryption and complex passwords. To view our full Privacy Policy <a data-link-type='external' target='_blank' data-label='click here' href='https://trustwillow.com/privacy-policy'>click here</a>.</div>
            },
        ]
    }
];