import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from '../../../../components/colors';
import {FontTitle40, FontTitle21} from '../../../../components/fonts';
import faqList from '../components/faqlist';

/* Containers */
import FAQ from './faq';

const Section = styled.div`
    margin-bottom: 40px;
    background-color: ${colors.background100};
    border-radius: 20px 0 20px 0;
    padding: 10px;
    margin-right: 20px;
`;
const SectionTitle = styled(FontTitle21)`
    padding: 15px 20px;
`;
const SectionTop = styled.div`
  margin-top: 40px;
  overflow-y: auto;
  height: calc(100% - 132px);
`;
const TitleFAQ = styled(FontTitle40)`
  margin-Bottom: 40px;
`;

export default function FAQs(props) {
    const setPage = props.setPage;

    return(
        <SectionTop>
            <TitleFAQ>Common Questions</TitleFAQ>
            {faqList.map((s, sIndex) => (
                <Section key={sIndex}>
                    <SectionTitle>{s.title}</SectionTitle>
                    {s.list.map((q, qIndex) => (
                        <div key={qIndex}>
                            <FAQ
                                title={q.title}
                                body={q.body}
                                action={setPage}
                                lastRecord={qIndex === (s.list.length-1)}
                            />
                        </div>
                    ))}
                </Section>
            ))}
        </SectionTop>
    )
}