import styled from 'styled-components';
import {colors} from '../../../../components/colors';

export const ActionBox = styled.div`
    background-color: ${colors.white};
    max-width: calc(600px - 100px);
    width: calc(100% - 100px);
    padding: 50px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    @media screen and (max-width: 640px) {
        max-height: 100%;
        max-width: calc(100% - 100px);
        width: calc(100% - 100px);
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
    }
`;
export const Background = styled.div`
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
`;
export const Section = styled.div`
    padding: 0 20px;
    margin-top: 40px;
    overflow-y: auto;
    height: calc(100% - 132px);
`;