import React from 'react';
import styled from 'styled-components';

/* Components */
import { colors } from '../../../components/colors';
import Button from '../../../components/button';
import {images} from "../../../components/images";
import {ButtonContainer} from '../components/q';

export default function Next(props) {
    const q = props.q;
    const qTotal = props.qTotal;
    const a = props.a;
    const onForward = props.onForward;

    return (
        <ButtonContainer>
            {/*<StackAdapt />*/}
            <Button
                isValid={a["q"+q]?.isValid}
                clickable={a["q"+q]?.isValid}
                onClick={onForward}
                label={q === qTotal ? "VIEW MATCHES" : "CONTINUE"}
                icon={images.arrowContinue}
                iconType={"img"}
                iconSide={"right"}
                colorButtonValid={colors.buttonActive}
                colorButtonInvalid={colors.buttonInactive}
                colorTextValid={colors.black}
                colorTextInvalid={colors.black}
                width={"100%"}
            />
        </ButtonContainer>
    );
};
