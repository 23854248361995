import React, {useState} from 'react';
import styled, {css} from 'styled-components';

/* Components */
import {colors} from '../../../../components/colors';
import {images} from '../../../../components/images';
import {FontBody18} from '../../../../components/fonts';

const DropdownTitle = styled.div`
    padding: 15px 0;
    color: ${colors.primary100};
    cursor: pointer !important;
    ${props => !props.$lastRecord && css`
        border-bottom: 1px solid ${colors.white};
    `}
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
`;
const Box = styled.div`
    width: 11px;
    height: 11px;
    padding: 8px;
    min-height: 11px;
    min-width: 11px;
`;
const Icon = styled.img`
    width:100%;
    height:100%;
    object-fit:contain;
`;
const Divider = styled.div`
    background-color: white;
    margin: 0 20px 20px 20px;
    padding: 20px;
    border-radius: 0 20px 0 20px;
`;
const Body = styled(FontBody18)`
    & a {
        color: ${colors.action100};
        text-decoration: underline !important;
    }
`;

export default function FAQ(props) {
    const title = props.title;
    const body = props.body;
    const lastRecord = props.lastRecord;
    const [expanded, setExpanded] = useState(false)

    const onClick = () => {setExpanded(!expanded)};

    return(
        <>
            <DropdownTitle
                onClick={onClick}
                $lastRecord={lastRecord || expanded}
            >
                <FontBody18>{title}</FontBody18>
                <Box>
                    <Icon src={expanded ? images.chevronDown : images.chevronRightDark} alt={">"}/>
                </Box>
            </DropdownTitle>
            {expanded &&
                <Divider><Body>{body}</Body></Divider>
            }
        </>
    )
}