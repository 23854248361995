import {
    faAddressCard,
    faChildren,
    faHeart,
    faPaw,
    faStar,
    faMagnifyingGlassDollar,
    faSeedling,
    faBagShopping,
    faPersonBurst,
    faComments,
    faMap,
    faPerson,
    faHouse,
    faHeartPulse,
    faHeartBroken,
    faChampagneGlasses,
    faRibbon,
    faBuilding,
    faIndustry,
    faLightbulb,
    faUserGroup,
    faUserTie,
    faVideo,
    faHandshakeSimple,
    faChildReaching,
    faPersonDigging,
    faHome,
    faGraduationCap,
    faBed,
    faUmbrellaBeach,
    faTableList,
    faMoneyBill,
    faMobileScreenButton,
} from "@fortawesome/free-solid-svg-icons";

const injectEmployedYesOrNo = [
    {
        "type": "qText",
        "dataKey": "industry",
        "dataKey2": "surveyProfession",
        "dataKeyQuestion": null,
        "icon": faIndustry,
        "title": "What is your profession?",
        "inputName": "industry",
        "inputAutocomplete": "organization-title",
    },
    {
        "type": "QRadio",
        "dataKey": "yearsToRetire",
        "dataKey2": "surveyYearsToRetire",
        "dataKeyQuestion": "How many years until your retirement?",
        "icon": null,
        "title": "How many years until your retirement?",
        "subtitle": null,
        "options": [
            {"title": "1-5 Years", "icon": null, "inject": null},
            {"title": "5-10 Years", "icon": null, "inject": null},
            {"title": "10-20 Years", "icon": null, "inject": null},
            {"title": "20+ Years", "icon": null, "inject": null}
        ],
        "slim": false
    }
]
const injectEmployerRetirementAccounts = [{
    "type": "qAssets",
    "dataKey": "employerRetirementAccounts",
    "dataKey2": "surveyEmployerRetirementAccounts",
    "dataKeyQuestion": "How much money is in your employer benefit accounts?",
    "icon": faMoneyBill,
    "title": "How much money is in your employer benefit accounts?",
    "min": 100000,
    "minLabel": "Under $100k",
    "max": 1000000, //5000000,
    "maxLabel": "Over $1m",
    "step": 50000, //100000
    "initValue": 250000,
    "subtitle": "Please do not include IRAs"
}]
const injectOtherRetirementAccounts = [{
    "type": "qAssets",
    "dataKey": "otherRetirementAccounts",
    "dataKey2": "surveyOtherRetirementAccounts",
    "dataKeyQuestion": "How much money is in your other retirement accounts?",
    "icon": faMoneyBill,
    "title": "How much money is in your other retirement accounts?",
    "min": 100000,
    "minLabel": "Under $100k",
    "max": 1000000, //5000000,
    "maxLabel": "Over $1m",
    "step": 50000, //100000
    "initValue": 500000,
    "subtitle": null
}]
const injectOtherTaxableAccounts = [{
    "type": "qAssets",
    "dataKey": "ropt",
    "dataKey2": "surveyRangeOfAssets",
    "dataKeyQuestion": null,
    "icon": faMoneyBill,
    "title": "How much money is in your other investment and savings accounts?",
    "min": 100000,
    "minLabel": "Under $100k",
    "max": 1000000, //5000000,
    "maxLabel": "Over $1m",
    "step": 50000, //100000
    "initValue": 250000,
    "subtitle": null
}]

export const initAllQuestions = [
    {
        "type": "QCheck",
        "dataKey": "needPartnerFor",
        "dataKey2": "surveyNeedPartnerFor",
        "dataKeyQuestion": "What do you need a partner for?",
        "icon": null,
        "title": "What do you need a partner for?",
        "subtitle": "Hi— We're Willow!",
        "options": [
            {"title": "Financial Planning", "icon": null},
            {"title": "Investing", "icon": null},
            {"title": "Trusts & Wills", "icon": null},
            {"title": "Insurance", "icon": null},
            {"title": "Retirement", "icon": null},
            {"title": "Divorce", "icon": null},
            {"title": "Recently Widowed", "icon": null},
            {"title": "Inheritance", "icon": null},
            {"title": "Real Estate", "icon": null},
            {"title": "Taxes", "icon": null},
        ],
        "allowMoreAfter": 4,
        "allowOther" : true,
        "otherPlaceholder": "How can we help?",
        "slim": false
    },
    {
        "type": "qName",
        "title": "What's your name?",
        "subtitle": "Tell us about you",
        "dataKey": "fullName",
        "icon": faAddressCard
    },
    {
        "type": "qEmail",
        "dataKey": "email",
        "dataKey2": "email",
        "dataKeyQuestion": null,
        "icon": faComments,
        "title": "What's your email?",
        "subtitle": "In case we get disconnected,",
        "inputName": "email",
        "inputAutocomplete": "email"
    },
    {
        "type": "qZip",
        "dataKey": "state",
        "dataKey2": "surveyState",
        "dataKeyQuestion": null,
        "icon": faMap,
        "title": "Where are you located",
        "subtitle": null,
        "inputName": "zip",
        "inputAutocomplete": "postal-code"
    },
    {
        "type": "QRadio",
        "dataKey": "maritalStatus",
        "dataKey2": "surveyMarital",
        "dataKeyQuestion": null,
        "icon": faHeart,
        "title": "What is your relationship status?",
        "subtitle": null,
        "options": [
            {"title": "Single", "icon": faPerson, "inject": null},
            {"title": "Relationship", "icon": faChampagneGlasses, "inject": null},
            {"title": "Married", "icon": faHeart, "inject": null},
            {"title": "Domestic Partnership", "icon": faHouse, "inject": null},
            {"title": "Getting divorced", "icon": faHeartPulse, "inject": null},
            {"title": "Divorced", "icon": faHeartBroken, "inject": null},
            {"title": "Widowed", "icon": faRibbon, "inject": null},
        ],
        "slim": false
    },
    {
        "type": "QRadio",
        "dataKey": "numChildren",
        "dataKey2": "surveyNumChildren",
        "dataKeyQuestion": null,
        "icon": faChildren,
        "title": "How many kids are financially dependent on you?",
        "subtitle": null,
        "options": [
            {"title": "0", "icon": null, "inject": null},
            {"title": "1", "icon": null, "inject": null},
            {"title": "2", "icon": null, "inject": null},
            {"title": "3+", "icon": null, "inject": null}
        ],
        "slim": false
    },
    {
        "type": "QCheck",
        "dataKey": "pets",
        "dataKey2": "surveyPets",
        "dataKeyQuestion": "Do you have any animal babies?",
        "icon": null,
        "title": "Do you have any animal babies?",
        "subtitle": null,
        "options": [
            {"title": "Dogs", "icon": null},
            {"title": "Cats", "icon": null},
            {"title": "Birds", "icon": null},
            {"title": "Fish", "icon": null},
            {"title": "Bunnies", "icon": null},
            {"title": "No pets", "icon": null},
        ],
        "allowMoreAfter": null,
        "allowOther" : true,
        "otherPlaceholder": "What kind of pets?",
        "slim": false
    },
    {
        "type": "QCheck",
        "dataKey": "interests",
        "dataKey2": "surveyInterests",
        "dataKeyQuestion": null,
        "icon": null,
        "title": "What do you do for fun?",
        "subtitle": null,
        "options": [
            {"title": "Travel", "icon": null},
            {"title": "Art", "icon": null},
            {"title": "Exercise", "icon": null},
            {"title": "Sports", "icon": null},
            {"title": "Reading", "icon": null},
            {"title": "None", "icon": null},
        ],
        "allowMoreAfter": null,
        "allowOther" : true,
        "otherPlaceholder": "What else makes you happy?",
        "slim": false
    },
    {
        "type": "QCheck",
        "dataKey": "lifeEvents",
        "dataKey2": "surveyLifeEvents",
        "dataKeyQuestion": "Do you have any events coming up?",
        "icon": null,
        "title": "Do you have any events coming up?",
        "subtitle": null,
        "options": [
            {"title": "Traveling", "icon": null},
            {"title": "Getting married", "icon": null},
            {"title": "Having a child", "icon": null},
            {"title": "Health events", "icon": null},
            {"title": "Retiring", "icon": null},
            {"title": "No events", "icon": null},
        ],
        "allowMoreAfter": null,
        "allowOther" : true,
        "otherPlaceholder": "What else is coming up?",
        "slim": false
    },
    {
        "type": "QCheck",
        "dataKey": "specialConsiderations",
        "dataKey2": "surveySpecialConsiderations",
        "dataKeyQuestion": "Any special considerations?",
        "icon": null,
        "title": "Any special considerations?",
        "subtitle": null,
        "options": [
            {"title": "Military family", "icon": null},
            {"title": "Special needs", "icon": null},
            {"title": "I'm a caretaker", "icon": null},
            {"title": "LGBTQ+ member", "icon": null},
            {"title": "Underrepresented group", "icon": null},
            {"title": "None", "icon": null},
        ],
        "allowMoreAfter": null,
        "allowOther" : true,
        "otherPlaceholder": "What else should we consider?",
        "slim": false
    },
    {
        "type": "QRadio",
        "dataKey": "employed",
        "dataKey2": "surveyCurrentlyEmployed",
        "dataKeyQuestion": null,
        "icon": faBagShopping,
        "title": "Are you currently employed?",
        "subtitle": "Next, a few work questions",
        "options": [
            {"title": "Yes", "icon": null, "inject": injectEmployedYesOrNo},
            {"title": "No", "icon": null, "inject": injectEmployedYesOrNo},
            {"title": "Retired", "icon": null, "inject": null}
        ],
        "slim": false
    },
    {
        "type": "qAssets",
        "dataKey": "income",
        "dataKey2": "surveyIncome",
        "dataKeyQuestion": "How much do you earn annually?",
        "icon": faMoneyBill,
        "title": "How much do you earn annually?",
        "min": 50000,
        "minLabel": "Under $50k",
        "max": 500000, //5000000,
        "maxLabel": "Over $500k",
        "step": 25000, //100000
        "initValue": 150000,
        "subtitle": "Include passive income & social security"
    },
    {
        "type": "QRadio",
        "dataKey": "ownBusiness",
        "dataKey2": "surveyBusinessOwn",
        "dataKeyQuestion": null,
        "icon": faBuilding,
        "title": "Do you own a business?",
        "subtitle": null,
        "options": [
            {"title": "Yes", "icon": null, "inject": null},
            {"title": "No", "icon": null, "inject": null}
        ],
        "slim": false
    },
    {
        "type": "QRadio",
        "dataKey": "workingWithFinAdvisor",
        "dataKey2": "surveyWorkingWithFinAdvisor",
        "dataKeyQuestion": null,
        "icon": faSeedling,
        "title": "Do you currently work with a financial advisor?",
        "subtitle": "Next, a few advisor questions",
        "options": [
            {"title": "Yes", "icon": null, "inject": null},
            {"title": "No", "icon": null, "inject": null}
        ],
        "slim": false
    },
    // {
    //     "type": "qText",
    //     "dataKey": "whyLookingForFinAdvisor",
    //     "dataKey2": "surveyWhyAdvisor",
    //     "dataKeyQuestion": null,
    //     "icon": faMagnifyingGlassDollar,
    //     "title": "Why are you looking for an advisor?",
    //     "subtitle": null
    // },
    {
        "type": "QCheck",
        "dataKey": "typeOfAdvisor",
        "dataKey2": "surveyAdvisorType",
        "dataKeyQuestion": null,
        "icon": faPersonBurst,
        "title": "Describe your perfect advisor",
        "subtitle": null,
        "options": [
            {"title": "Introverted", "icon": null},
            {"title": "Extroverted", "icon": null},
            {"title": "Practical", "icon": null},
            {"title": "Insightful", "icon": null},
            {"title": "Logical", "icon": null},
            {"title": "Empathetic", "icon": null},
            {"title": "Decisive", "icon": null},
            {"title": "Adaptable", "icon": null},
        ],
        "allowMoreAfter": null,
        "allowOther" : false,
        "otherPlaceholder": null,
        "slim": false
    },
    {
        "type": "QRadio",
        "dataKey": "frequencyOfCommunications",
        "dataKey2": "surveyFrequencyOfCommunications",
        "dataKeyQuestion": "How often do you want to talk to your advisor?",
        "icon": null,
        "title": "How often do you want to talk to your advisor?",
        "subtitle": null,
        "options": [
            {"title": "Monthly", "icon": null, "inject": null},
            {"title": "Quarterly", "icon": null, "inject": null},
            {"title": "Every 6 Months", "icon": null, "inject": null},
            {"title": "Annually", "icon": null, "inject": null}
        ],
        "slim": false
    },
    {
        "type": "QCheck",
        "dataKey": "currentFinancialGoals",
        "dataKey2": "surveyFinGoals",
        "dataKeyQuestion": null,
        "icon": faTableList,
        "title": "What are your financial goals?",
        "subtitle": "Next, a few financial questions",
        "options": [
            {"title": "Grow Wealth", "icon": null},
            {"title": "Create a Budget", "icon": null},
            {"title": "Invest Better", "icon": null},
            {"title": "Afford School", "icon": null},
            {"title": "Buy Property", "icon": null},
            {"title": "Manage Debt", "icon": null},
        ],
        "allowMoreAfter": null,
        "allowOther" : true,
        "otherPlaceholder": "What are your other goals?",
        "slim": false
    },
    {
        "type": "QRadio",
        "dataKey": "hasEmployerRetirementAccounts",
        "dataKey2": "surveyHasEmployerRetirementAccounts",
        "dataKeyQuestion": "Do you have a 401k, 403b, or other employer benefit account?",
        "icon": null,
        "title": "Do you have a 401k, 403b, or other employer benefit account?",
        "subtitle": null,
        "options": [
            {"title": "Yes", "icon": null, "inject": injectEmployerRetirementAccounts},
            {"title": "No", "icon": null, "inject": null}
        ],
        "slim": false
    },
    {
        "type": "QRadio",
        "dataKey": "hasOtherRetirementAccounts",
        "dataKey2": "surveyHasOtherRetirementAccounts",
        "dataKeyQuestion": "Do you have any other retirement accounts like an IRA, Roth IRA, SEP, etc?",
        "icon": null,
        "title": "Do you have any other retirement accounts like an IRA, Roth IRA, SEP, etc?",
        "subtitle": null,
        "options": [
            {"title": "Yes", "icon": null, "inject": injectOtherRetirementAccounts},
            {"title": "No", "icon": null, "inject": null}
        ],
        "slim": false
    },
    {
        "type": "QRadio",
        "dataKey": "hasOtherTaxableAccounts",
        "dataKey2": "surveyHasOtherTaxableAccounts",
        "dataKeyQuestion": "Do you have any other investments or savings accounts?",
        "icon": null,
        "title": "Do you have any other investments or savings accounts?",
        "subtitle": null,
        "options": [
            {"title": "Yes", "icon": null, "inject": injectOtherTaxableAccounts},
            {"title": "No", "icon": null, "inject": null}
        ],
        "slim": false
    },
    {
        "type": "QRadio",
        "dataKey": "hasDebt",
        "dataKey2": "surveyHasDebt",
        "dataKeyQuestion": "Do you have any loans or credit card debt?",
        "icon": null,
        "title": "Do you have any loans or credit card debt?",
        "subtitle": null,
        "options": [
            {"title": "Yes", "icon": null, "inject": null},
            {"title": "No", "icon": null, "inject": null}
        ],
        "slim": false
    },
    {
        "type": "QRadio",
        "dataKey": "hasHomeowner",
        "dataKey2": "surveyHomeowner",
        "dataKeyQuestion": "Do you own a house or other real estate?",
        "icon": null,
        "title": "Do you own a house or other real estate?",
        "subtitle": null,
        "options": [
            {"title": "Yes", "icon": null, "inject": null},
            {"title": "No", "icon": null, "inject": null}
        ],
        "slim": false
    },
    {
        "type": "QCheck",
        "dataKey": "referral",
        "dataKey2": "surveyReferral",
        "dataKeyQuestion": null,
        "icon": faComments,
        "title": "How did you hear about us?",
        "subtitle": "Last question",
        "options": [
            {"title": "Google", "icon": null},
            {"title": "Article", "icon": null},
            {"title": "TV", "icon": null},
            {"title": "Podcast", "icon": null},
            {"title": "Social Media", "icon": null},
            {"title": "Word of Mouth", "icon": null},
        ],
        "allowMoreAfter": null,
        "allowOther" : true,
        "otherPlaceholder": "How did you find us?",
        "slim": true
    },
    {
        "type": "QPhone",
        "dataKey": "phone",
        "dataKey2": "phone",
        "dataKeyQuestion": null,
        "icon":faMobileScreenButton,
        "title":"What is your phone number?",
        "subtitle": "It’s time to get matched! We'll call to help connect you with one matched advisor.",
        // "subtitle":"A member of Willow’s Customer Success team will call you to review your matches and answer any questions. Your phone number will not be shared with advisors without your permission.",
        "inputName":"SMSPHONE",
        "inputAutocomplete": "000-000-0000"
    },
]
