import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector} from "react-redux";

/* Components */
import {FontSubtitle28, FontBody14, FontHeader14} from "../../../components/fonts";
import {colors} from "../../../components/colors";
import {faEnvelope, faMobile} from "@fortawesome/free-solid-svg-icons";

/* Middleware */
import {tryGetMessages} from "./middleware/messages";
import {DateTime} from "luxon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Intro = styled.div`
  width: calc(100% - 60px);
  max-width: 900px;
  margin: 0 auto;
  padding: 0 30px; 
  display:flex;
  flex-direction:column;
  gap: 10px;
  text-align: center;
  @media screen and (max-width: 1088px) {
    padding: 0;
    width: 100%;
    & > div {
      font-size: 20px;
    }
    margin-bottom: -10px;
  }
`;
const AllMatches = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.background100};
  padding-bottom: 10px;
`;
const Cards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
  margin: 0 10px;
  @media screen and (max-width: 780px) {
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 20px;
  }
`;
const Card = styled.div`
  text-align: center;
  border-radius: 10px;
  padding: 20px 10px;
  width: calc(100% - 20px);
  //max-width: 50%;
  //min-width: calc(50% - 60px - 20px);
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 780px) {
    //max-width: 100%;
    padding: 20px 10px;
    width: calc(100% - 20px);
  }
`;
const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
`;
const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Icon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
`;

export default function Messages() {
    const [messages, setMessages] = useState([])
    const t1Store = useSelector(state => state.session.t1);

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        console.log(messages)
    }, [messages])

    const init = async () => {
        const m = await tryGetMessages(t1Store)
        setMessages(messageReduce(m.data).sort(sortOrder))
    }

    const sortOrder = (a, b) => b.d - a.d;

    const messageReduce = (p) => {
        return(p.reduce((filtered, r) => {
            if(r.event_answer !== "Enrolled") {
                filtered.push({"o": parseFloat(r.event_name.replace("JOURNEY", "")), "s": r.event_answer, "d": DateTime.fromISO(r.event_date)})
            }
            return filtered;
        }, []))
    }

    return (
        <AllMatches>
            <Intro>
                {messages.length === 0
                    ? <FontSubtitle28 $spaced={true}>No Messages</FontSubtitle28>
                    : <>
                        <FontSubtitle28 $spaced={true}>My Messages</FontSubtitle28>
                        <br />
                        <Cards>
                            <Card>
                                {messages.map((p, pIndex) => (
                                    <Row key={pIndex}>
                                        <Title>
                                            <IconContainer>
                                                <Icon icon={p.s.includes("Sent Email") ? faEnvelope : faMobile}/>
                                            </IconContainer>
                                            {p.s.includes("Sent Email")
                                                ? <FontHeader14>Subject:&nbsp;</FontHeader14>
                                                : <FontHeader14>Message:&nbsp;</FontHeader14>
                                            }
                                            <FontBody14>{p.s.split(" - ")[1]}</FontBody14>
                                        </Title>
                                        <FontBody14>{p.d.toFormat("M/d/yyyy")}</FontBody14>
                                    </Row>
                                ))}
                            </Card>
                        </Cards>
                    </>
                }
            </Intro>
        </AllMatches>
    );
};