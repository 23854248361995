import React from 'react';
import styled, {css} from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useDispatch } from 'react-redux';

/* Components */
import { FontBody18 } from "../../../../components/fonts";
import { colors } from "../../../../components/colors";
import nav from '../components/nav';

/* Store */
import { setSupportOpen } from "../../../../utils/store";

const NavSide = styled.div`
  -webkit-box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.06);
  width: 320px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  background-color: ${colors.primary100};
  display: block;
  @media screen and (max-width: 1088px) {
    display: none;
  }
`;
const Container = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const LogoDate = styled.div`
  height: 40px;
  padding: 55px 10px 25px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const NavSection = styled.div`
    padding: 10px 10px;
    width: fit-content;
`;
const LogOut = styled.div`
    border-style: solid;
    border-color: white;
    border-radius: 30px;
    border-width: thin;
    //margin: 35px auto 0 auto;
    width: fit-content;
    padding: 10px 60px;
    text-align: center;
    color: ${colors.white};
    cursor: pointer;
    margin: 35px 0 35px 35px;
`;

const IconTextContainer = styled.div`
    display:flex; 
    flex-direction:row;
    align-items: center;
    padding-left: 15px;
    width: fit-content;
    cursor: pointer;
`;
const Button = styled.div`
    color: ${colors.primary100};
    ${props => props.$status && css`
        color: ${colors.canvaGreen};
        font-weight: bold;
    `}
    ${props => !props.$status && css`
        color: ${colors.white};
    `}
    padding-left: 10px;
    width: fit-content;
    cursor: pointer;
`;
const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Icon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
`;
const LinkSection = ({data, status}) => (
    <IconTextContainer>
        {data.icon !== null &&
            <IconContainer>
                <Icon icon={data.icon} style={{"color": (status === "active" ? colors.canvaGreen : colors.white)}} />
            </IconContainer>
        }
        <Button $status={status === 'active'}>
            <FontBody18>{data.label}</FontBody18>
        </Button>
    </IconTextContainer>
)

export default function Side() {
    const location = useLocation();
    const dispatch = useDispatch();

    return (
        <NavSide>
            <Container>
                <div>
                    <LogoDate>
                        <img src={"https://docs.trustwillow.com/logotransparentwhite.png"} height="32" width="160" alt={"Willow"}/>
                    </LogoDate>
                    {nav.map((n, nIndex) => (
                        <NavSection key={nIndex}>
                            {n.map((d, index) => {
                                let status = "inactive";
                                if (location.pathname.split("/")[1] === d.href.split("/")[1]) {
                                    status = "active"
                                }
                                if(d.href === "modalSupport") {
                                    return(
                                        <div key={index} onClick={() => {dispatch(setSupportOpen({"support_page": 1}))}} style={{cursor: "default"}}>
                                            <LinkSection data={d} status={status} />
                                        </div>
                                    )
                                } else {
                                    return(
                                        <Link key={index} to={d.href} style={{cursor: "default"}}>
                                            <LinkSection data={d} status={status} />
                                        </Link>
                                    )
                                }
                            })}
                        </NavSection>
                    ))}
                </div>
                <LogOut onClick={() => {console.log("tryLogout");window.location.replace("https://trustwillow.com")}}><FontBody18>Log Out</FontBody18></LogOut>
            </Container>
        </NavSide>
    );
};


