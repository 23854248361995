import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import { ActionBox, Background, Section } from '../components/support';
import { ActionBarLeftBar, SelectionModal } from "../components/actionbar";
import { images } from "../../../../components/images";

/* Containers */
import Form from './form';
import FAQs from './faqs';

/* Store */
import { setSupportOpen } from '../../../../utils/store';

export default function Support() {
    const supportPage = useSelector(state => state.session.support_page);
    const dispatch = useDispatch();

    const setPage = (page) => {dispatch(setSupportOpen({"support_page": page}))};

    if(supportPage === null) {
        return null
    }

    return(
        <>
            <Background onClick={() => {setPage(null)}} />
            <ActionBox>
                <ActionBarLeftBar
                    supportPage={supportPage}
                    setPage={() => {setPage(1)}}
                    closeSupport={() => {setPage(null)}}
                />
                {supportPage === 1 &&
                    <Section>
                        <SelectionModal
                            onClick={() => {setPage(3)}}
                            icon={images.firmSupportFAQ}
                            label={"FAQs"}
                        />
                        <SelectionModal
                            onClick={() => {setPage(2)}}
                            icon={images.firmSupportGeneral}
                            label={"Tech Support"}
                        />
                        <SelectionModal
                            onClick={() => {setPage(4)}}
                            icon={images.firmSupportFeedback}
                            label={"Feedback"}
                        />
                    </Section>
                }
                {supportPage === 2 &&
                    <Form
                        label={"Tech Support"}
                        placeholder={"How can we help?"}
                        response={"Our Customer Service team contact you soon!"}
                        apiURL={"apiv1/support"}
                    />
                }
                {supportPage === 3 &&
                    <FAQs
                        setPage={setPage}
                    />
                }
                {supportPage === 4 &&
                    <Form
                        label={"Have feedback?"}
                        placeholder={"We love feedback! Tell us what you like or what we can do better."}
                        response={"Thank you for your feedback!"}
                        apiURL={"apiv1/feedback"}
                    />
                }
            </ActionBox>
        </>
    )
}