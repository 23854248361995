import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';

/* Pages */
import Entry from './pages/entry';
import Matches from "./pages/matches/page";
import Profile from "./pages/profile/page";
import Login from "./pages/login/page";
import Error from "./pages/error/page";

export default function AllRoutes() {
    return(
        <Routes>
            <Route exact path={'/'} element={<Entry />} />
            <Route exact path={'/matching'} element={<Matches />} />
            <Route exact path={'/advisors'} element={<Matches />} />
            <Route exact path={'/advisors/:id'} element={<Profile />} />
            <Route exact path={'/profile'} element={<Matches />} />
            <Route exact path={'/messages'} element={<Matches />} />
            <Route exact path={'/refer'} element={<Matches />} />
            <Route exact path={'/error'} element={<Error />} />
            <Route exact path={'/login'} element={<Login />} />
            <Route path={'/*'} element={<Navigate to={'/'} replace />} />
        </Routes>
    )
}