import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector} from "react-redux";
import {DateTime} from "luxon";

/* Components */
import {FontBody14, FontTitle18, FontSubtitle28, FontHeader14} from "../../../components/fonts";

/* Middleware */
import {tryGetProfile} from "./middleware/profile";

const Intro = styled.div`
  width: calc(100% - 60px);
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 30px;
  display:flex;
  flex-direction:column;
  gap: 10px;
  text-align: left;
  @media screen and (max-width: 1088px) {
    padding: 0;
    width: 100%;
    & > div {
      font-size: 20px;
    }
    margin-bottom: -10px;
  }
`;
const AllMatches = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
`;
const Center = styled.div`
  text-align: center;
`;
const Cards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
  margin: 30px 10px;
  @media screen and (max-width: 780px) {
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 20px;
  }
`;
const Card = styled.div`
  text-align: center;
  box-shadow: 0 3px 10px 0 rgb(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px;
  max-width: 50%;
  min-width: calc(50% - 60px - 20px);
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 780px) {
    max-width: 100%;
    padding: 20px;
  }
`;
const CardTitle = styled(FontTitle18)`
    margin-bottom: 16px;
`;

export default function Messages() {
    const [profile, setProfile] = useState({
        "personalInfo": [],
        "professionalInfo": [],
        "advisorInfo": [],
        "financialInfo": [],
        "lastUpdated": null
    })
    const t1Store = useSelector(state => state.session.t1);

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        console.log(profile)
    }, [profile])

    const init = async () => {
        const p = await tryGetProfile(t1Store)
        const pUnique = {};
        p.data.forEach(i => {
            if (!pUnique[i.event_name] || new Date(i.event_date) > new Date(pUnique[i.event_name].event_date)) {
                pUnique[i.event_name] = i;
            }
        });
        const u = Object.values(pUnique);
        setProfile({
            "personalInfo": personalInfo(u).map(mapSkipped).sort(sortOrder),
            "professionalInfo": professionInfo(u).map(mapSkipped).sort(sortOrder),
            "advisorInfo": advisorInfo(u).sort(sortOrder),
            "financialInfo": financialInfo(u).sort(sortOrder),
            "lastUpdated": DateTime.fromISO(u.sort((a, b) => DateTime.fromISO(b.event_date) - DateTime.fromISO(a.event_date))[0].event_date).toFormat("M/d/yyyy")
        })
    }

    const mapSkipped = i => {
        let iAdj = i;
        if(iAdj.a === null || iAdj.a === undefined || iAdj.a === "") {
            iAdj.a = "Skipped"
        }
        return iAdj;
    }

    const sortOrder = (a, b) => a.o - b.o;

    const personalInfo = (p) => {
        return(p.reduce((filtered, r) => {
            if(r.event_name === "surveyLifeEvents") {
                filtered.push({"o": 1, "q": "Journeys", "a": JSON.parse(r.event_answer).capturedSurveylifeevents, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "surveySpecialConsiderations") {
                filtered.push({"o": 2, "q": "Considerations", "a": JSON.parse(r.event_answer).capturedSurveyspecialconsiderations, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "maritalStatus") {
                filtered.push({"o": 3, "q": "Family", "a": JSON.parse(r.event_answer).capturedMaritalStatus, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "numChildren") {
                filtered.push({"o": 4, "q": "Dependents", "a": JSON.parse(r.event_answer).capturedNumChildren, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "pets") {
                filtered.push({"o": 5, "q": "Pets", "a": JSON.parse(r.event_answer).capturedPets, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "interests") {
                filtered.push({"o": 6, "q": "Interests", "a": JSON.parse(r.event_answer).capturedInterests, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "state") {
                filtered.push({"o": 7, "q": "State", "a": JSON.parse(r.event_answer).capturedState, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            }
            return filtered;
        }, []))
    }

    const professionInfo = (p) => {
        return(p.reduce((filtered, r) => {
            if(r.event_name === "surveyProfession") {
                filtered.push({"o": 1, "q": "Profession", "a": JSON.parse(r.event_answer).capturedProfession, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "surveyYearsToRetire") {
                filtered.push({"o": 3, "q": "Retirement", "a": JSON.parse(r.event_answer).capturedSurveyyearstoretire, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "surveyIncome") {
                filtered.push({"o": 2, "q": "Income", "a": new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0}).format(JSON.parse(r.event_answer).capturedSurveyincome), "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "businessOwn") {
                filtered.push({"o": 4, "q": "Business owner", "a": JSON.parse(r.event_answer).capturedBusinessOwn, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            }
            return filtered;
        }, []))
    }

    const advisorInfo = (p) => {
        return(p.reduce((filtered, r) => {
            if(r.event_name === "surveyNeedPartnerFor") {
                filtered.push({"o": 1, "q": "I need help", "a": JSON.parse(r.event_answer).capturedSurveyneedpartnerfor, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "advisorType") {
                filtered.push({"o": 2, "q": "From an advisor who is", "a": JSON.parse(r.event_answer).capturedAdvisorType, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            // } else if(r.event_name === "workingWithAdvisor") {
            //     filtered.push({"o": 4, "q": "Do I have an advisor?", "a":JSON.parse(r.event_answer).capturedWorkingWithAdvisor, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "surveyFrequencyOfCommunications") {
                filtered.push({"o": 3, "q": "They can update me", "a": JSON.parse(r.event_answer).capturedSurveyfrequencyofcommunications, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            }
            return filtered;
        }, []))
    }

    const financialInfo = (p) => {
        return(p.reduce((filtered, r) => {
            if(r.event_name === "finGoals") {
                filtered.push({"o": 1, "q": "Financial goals", "a": JSON.parse(r.event_answer).capturedFinGoals, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "surveyEmployerRetirementAccounts") {
                filtered.push({"o": 2, "q": "Employer benefit accounts", "a": new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0}).format(JSON.parse(r.event_answer).capturedSurveyemployerretirementaccounts), "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "surveyOtherRetirementAccounts") {
                filtered.push({"o": 3, "q": "Other retirement accounts", "a":new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0}).format(JSON.parse(r.event_answer).capturedSurveyotherretirementaccounts), "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "rangeAssets") {
                filtered.push({"o": 4, "q": "Investment & savings accounts", "a": new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0}).format(JSON.parse(r.event_answer).actualAssetsAnswer), "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "surveyHasDebt") {
                filtered.push({"o": 5, "q": "I have debt", "a": JSON.parse(r.event_answer).capturedSurveyhasdebt, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            } else if(r.event_name === "surveyHomeowner") {
                filtered.push({"o": 6, "q": "I own a home", "a": JSON.parse(r.event_answer).capturedSurveyhomeowner, "d": DateTime.fromISO(r.event_date).toFormat("M/d/yyyy")})
            }
            return filtered;
        }, []))
    }

    return (
        <AllMatches>
            <Intro>
                {profile.length === 0
                    ? <Center><FontSubtitle28 $spaced={true}>No Profile</FontSubtitle28></Center>
                    : <>
                        <Center>
                            <FontSubtitle28 $spaced={true}>My Profile</FontSubtitle28>
                            {profile.lastUpdated !== null && <FontBody14>Last updated:&nbsp;{profile.lastUpdated}</FontBody14>}
                        </Center>
                        <Cards>
                            <Card>
                                <CardTitle>Personal Info</CardTitle>
                                {profile.personalInfo.map((p, pIndex) => (
                                    <Row key={pIndex}>
                                        <Title>
                                            <FontHeader14>{p.q}</FontHeader14>:&nbsp;<FontBody14>{p.a}</FontBody14>
                                        </Title>
                                        {/*<FontBody14>{p.d}</FontBody14>*/}
                                    </Row>
                                ))}
                            </Card>
                            <Card>
                                <CardTitle>Professional Info</CardTitle>
                                {profile.professionalInfo.map((p, pIndex) => (
                                    <Row key={pIndex}>
                                        <Title>
                                            <FontHeader14>{p.q}</FontHeader14>:&nbsp;<FontBody14>{p.a}</FontBody14>
                                        </Title>
                                        {/*<FontBody14>{p.d}</FontBody14>*/}
                                    </Row>
                                ))}
                            </Card>
                            <Card>
                                <CardTitle>Advisor Info</CardTitle>
                                {profile.advisorInfo.map((p, pIndex) => (
                                    <Row key={pIndex}>
                                        <Title>
                                            <FontHeader14>{p.q}</FontHeader14>:&nbsp;<FontBody14>{p.a}</FontBody14>
                                        </Title>
                                        {/*<FontBody14>{p.d}</FontBody14>*/}
                                    </Row>
                                ))}
                            </Card>
                            <Card>
                                <CardTitle>Financial Info</CardTitle>
                                {profile.financialInfo.map((p, pIndex) => (
                                    <Row key={pIndex}>
                                        <Title>
                                            <FontHeader14>{p.q}</FontHeader14>:&nbsp;<FontBody14>{p.a}</FontBody14>
                                        </Title>
                                        {/*<FontBody14>{p.d}</FontBody14>*/}
                                    </Row>
                                ))}
                            </Card>
                        </Cards>
                    </>
                }
            </Intro>
        </AllMatches>
    );
};