import React, {useEffect, useState} from 'react';

/* Containers */
import QText from './qtext';
import QName from './qname';
import QZip from './qzip';
import QRadio from "./qradio";
import QCheck from "./qcheck";
import QEmail from './qemail';
import QAssets from "./qassets";
import QPhone from "./qphone";
import QPhoneMC from "./qphonemc";

export default function Q(props) {
    const q = props.q
    const [qLocal, setQLocal] = useState(props.q)
    const onFork = props.onFork;
    const onForward = props.onForward
    const setQ = props.setQ;
    const color = props.color;

    const onAnswer = (answer) => {
        setQ({...qLocal, "a": {...qLocal.a, ...answer}})
    }

    useEffect(() => {
        setQLocal(q)
    }, [q])

    return (
        <>
            {qLocal.allQuestions.map((i, iIndex) => {
                if(qLocal.num === (iIndex+1)) {
                    if(i.type === "qText") {
                        return(<QText key={iIndex} q={q.num} a={qLocal.a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "qZip") {
                        return(<QZip key={iIndex} q={q.num} a={qLocal.a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "qName") {
                        return(<QName key={iIndex} q={q.num} a={qLocal.a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "QRadio") {
                        return (<QRadio key={iIndex} q={qLocal} a={qLocal.a} onAnswer={onAnswer} i={i} color={color} onFork={onFork}/>)
                    } else if(i.type === "QCheck") {
                        return(<QCheck key={iIndex} q={q.num} a={qLocal.a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "qEmail") {
                        return(<QEmail key={iIndex} q={q.num} a={qLocal.a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "qAssets") {
                        return(<QAssets key={iIndex} q={qLocal.num} a={qLocal.a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "QPhone") {
                        return(<QPhoneMC key={iIndex} q={q.num} a={qLocal.a} onAnswer={onAnswer} i={i} color={color} onForward={onForward} />)
                    }
                }
            })}
        </>
    );
};