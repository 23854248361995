import {faStar, faUser, faMessage, faShare, faCircleQuestion} from "@fortawesome/free-solid-svg-icons";

export default [
    [
        {
            label: "My Advisors",
            href: "/advisors",
            icon: faStar
        },
        {
            label: "My Profile",
            href: "/profile",
            icon: faUser
        },
        {
            label: "My Messages",
            href: "/messages",
            icon: faMessage
        }
    ],
    [
        // {
        //     label: "Refer & Earn",
        //     href: "/refer",
        //     icon: faShare
        // },
        {
            label: "Support",
            href: "modalSupport",
            icon: faCircleQuestion
        },
    ]
];