import React, {useEffect, useRef, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* Components */
import { colors } from '../../../components/colors'

/* Components */
import {Title, Subtitle, Boxes, Box, IconTop, IconBtns, OptionTitle, Input} from '../components/q'
import {FontHeader48, FontSubtitle20} from "../../../components/fonts";

export default function QCheck(props) {
    const a = props.a;
    const q = props.q;
    const icon = props.i.icon;
    const title = props.i.title;
    const options = props.i.options;
    const subtitle = props.i.subtitle;
    const dataKey = props.i.dataKey;
    const dataKey2 = props.i.dataKey2;
    const dataKeyQuestion = props.i.dataKeyQuestion;
    const onAnswer = props.onAnswer;
    const color = props.color;
    const slim = props.i.slim;
    const allowMoreAfter = props.i.allowMoreAfter;
    const allowOther = props.i.allowOther;
    const otherPlaceholder = props.i.otherPlaceholder
    const otherCTA = "Other, please describe";
    const [isSelected, setIsSelected] = useState([]);
    const [openMore, setOpenMore] = useState(false)
    const [openText, setOpenText] = useState(false)
    const inputRef = useRef(null);
    const inputHiddenRef = useRef(null);
    const [otherText, setOtherText] = useState("")
    const [isNew, setIsNew] = useState(true);
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        const arr = a["q"+q]?.[dataKey].split(", ");
        setIsSelected(a["q"+q]?.[dataKey] === undefined ? [] : arr)
        if(arr !== undefined) {
            onLoadCheckOtherText(arr)
        }
        inputHiddenRef.current.focus();
    }, [])

    const onLoadCheckOtherText = async (arr) => {
        // console.log(arr)
        const optionsCheck = await Promise.all(options.map(o => {return(o.title)}))
        // const optionsCheckAlwaysShow = optionsCheck.slice(0, allowMoreAfter+1)
        const optionsCheckAlwaysHide = optionsCheck.slice(allowMoreAfter+1)
        arr.map(a => {
            if(optionsCheckAlwaysHide.includes(a)) {
                setOpenMore(true)
            }
            if(!optionsCheck.includes(a)) {
                setOtherText(a)
                setOpenText(true)
            }
        })
    }

    const onSelect = (title) => {
        if (title === otherCTA) {
            if (openText) {
                setOpenText(false)
                setIsSelected(isSelected.filter(f => f !== otherText))
            } else {
                setOpenText(true)
            }
        }
        else {
            if (isSelected.length === 0) {
                    setIsSelected([title])

            } else if (isSelected.includes(title)) {
                setIsSelected(isSelected.filter(f => {
                    return (f !== title)
                }))
            } else {
                    setIsSelected([...isSelected, title])


            }
        }
    }

    const onChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        const isValid = value.replace(/\s/g, '').length > 0
        // onAnswer({["q" + q]: {[dataKey]: [...isSelected, `Other, please describe: ${value}`], "isValid": isValid}})
        setOtherText(value)
        setIsValid(true)
        if(isNew) {
            setIsNew(false);
        }
    }

    const setSelect = () => {
        setIsSelected([...isSelected, `${otherText}`])
    }


    useEffect(() => {
        // console.log("isSelected", isSelected)
        if(isSelected.length > 0) {
            onAnswer({["q"+q]: {[dataKey]: isSelected.join(", "), "isValid": isSelected.length > 0 || isValid, "dataKey2": dataKey2, "dataKeyQuestion": dataKeyQuestion}})
        } else {
            onAnswer({["q"+q]: {[dataKey]: "", "isValid": false, "dataKey2": dataKey2, "dataKeyQuestion": dataKeyQuestion}})
        }
    }, [isSelected, isValid])

    return(
        <>
            {/*{icon !== null &&*/}
            {/*    <IconTop>*/}
            {/*        <FontAwesomeIcon*/}
            {/*            icon={icon}*/}
            {/*            style={{"color": color, fontSize: '30px'}}*/}
            {/*        />*/}
            {/*    </IconTop>*/}
            {/*}*/}
            <FontSubtitle20 style={{
                marginBottom: '20px',
            }} $spaced={true}>{subtitle}</FontSubtitle20>
            <FontHeader48
            >{title}</FontHeader48>
            <Subtitle></Subtitle>
            <Boxes $slim={slim}>
                {options.map((o, oIndex) => {
                    if (
                        o.title !== 'Other, please describe' &&
                        ((allowMoreAfter === undefined || allowMoreAfter === null) || ((
                            (!openMore && allowMoreAfter >= oIndex) ||
                            (openMore)
                        )))
                    ) {
                        return <Box
                            key={oIndex}
                            onClick={() => {
                                onSelect(o.title)
                                inputHiddenRef.current.focus();
                            }}
                            $selected={isSelected.length === 0 ? false : isSelected.includes(o.title)}
                            $color={color}
                            $slim={slim}
                            $isMoreBtn={false}
                        >
                            {o.icon !== null &&
                                <IconBtns>
                                    <FontAwesomeIcon
                                        icon={o.icon}
                                        style={{
                                            "color": (isSelected.includes(o.title) ? colors.white : color),
                                            fontSize: '30px'
                                        }}
                                    />
                                </IconBtns>
                            }
                            <OptionTitle>{o.title}</OptionTitle>
                        </Box>
                    }
                })}
                {(allowMoreAfter !== undefined && allowMoreAfter !== null && !openMore) &&
                    <Box $isMoreBtn={true} onClick={() => setOpenMore(true)} >
                        <OptionTitle>+ see more</OptionTitle>
                    </Box>
                }
            </Boxes>

            <br />
            <br />
            {!openText && allowOther &&
                <OptionTitle onClick={() => setOpenText(true)} style={{cursor : 'pointer'}}>{otherCTA}</OptionTitle>
            }
            {openText &&
                <>
                    <br />
                    <Input
                        ref={inputRef}
                        type={"text"}
                        defaultValue={otherText}
                        onChange={onChange}
                        onBlur={setSelect}
                        $isValid={isNew ? true : a["q"+q]?.isValid}
                        name={otherText}
                        autoComplete={"other"}
                        placeholder={otherPlaceholder}
                    />
                </>
            }
            <input
                ref={inputHiddenRef}
                type={"text"}
                value={""}
                onChange={() => {}}
                style={{"opacity": "0", "height": "1px", "cursor": "default"}}
            />
        </>
    )
}