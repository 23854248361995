import {axiosReq} from "../../../../utils/axios";

const devData = {data: [
        {
            "id": 1146,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "JOURNEY1.1",
            "event_answer": "Enrolled",
            "event_date": "2025-01-22T21:56:17.393Z",
            "event_source": "mailchimp"
        },
        {
            "id": 1147,
            "anon_id": "f57cace9-3a85-4a1c-9e40-d54d00ab5302",
            "event_name": "JOURNEY1.2",
            "event_answer": "Sent Email - FA Survey Incomplete Email 1 - Hi, It's Willow",
            "event_date": "2025-01-22T21:59:14.251Z",
            "event_source": "mailchimp"
        }
    ]
}

export const tryGetMessages = (data) => {
    return axiosReq('https://reportingapi.trustwillow.com/api/events/anon-id/'+data+'/event-source/mailchimp', "GET", {},  devData);
};
