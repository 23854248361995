export const images = {

    // Logos
    "logo": "https://docs.trustwillow.com/willowlogotransparent.png",
    "logoTransparentWhite": "https://docs.trustwillow.com/logotransparentwhite.png",
    "logoIcon": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061609/ltahv3jg7dbabxc0eblc.png",
    "logoGray": "https://docs.trustwillow.com/logogray.webp",

    // Error Page
    "error1": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061902/j5wbelwjtfg4gjjvoom3.png",
    "error2": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061948/tdg8knv9isuqiodkl6hx.png",
    "error3": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1615061980/uzxpckc4cyd8mhuerllf.png",

    // AI Page
    "messageIcon": "https://docs.trustwillow.com/msg-icon-tmp.png",

    // Profile Page
    "helpCircle": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635522681/mrhpzsqcfffefxhrqhlx.png",
    "faq": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1635522846/n7hj6js6rwtw3njf7eiq.png",
    "OldestIcon": "https://docs.trustwillow.com/Oldest icon.png",
    "OnlyChildIcon": "https://docs.trustwillow.com/Only child.png",
    "YoungestIcon": "https://docs.trustwillow.com/Youngest icon.png",
    "AmphibianIcon": "https://docs.trustwillow.com/Amphibian icon.png",
    "BearIcon": "https://docs.trustwillow.com/Bear icon.png",
    "BirdIcon": "https://docs.trustwillow.com/Bird icon.png",
    "CatIcon": "https://docs.trustwillow.com/Cat icon.png",
    "ChickenIcon": "https://docs.trustwillow.com/Chicken icon.png",
    "CowIcon": "https://docs.trustwillow.com/Cow icon.png",
    "MiddleIcon": "https://docs.trustwillow.com/Middle icon.png",
    "DogIcon": "https://docs.trustwillow.com/Dog icon.png",
    "DolphinIcon": "https://docs.trustwillow.com/Dolphin icon.png",
    "ElephantIcon": "https://docs.trustwillow.com/Elephant.png",
    "FishIcon": "https://docs.trustwillow.com/Fish icon.png",
    "FoxIcon": "https://docs.trustwillow.com/Fox icon.png",
    "GiraffeIcon": "https://docs.trustwillow.com/Giraffe icon.png",
    "HorseIcon": "https://docs.trustwillow.com/Horse icon.png",
    "LionIcon": "https://docs.trustwillow.com/Lion icon.png",
    "MonkeyIcon": "https://docs.trustwillow.com/Monkey icon.png",
    "OtterIcon": "https://docs.trustwillow.com/Otter icon.png",
    "PigIcon": "https://docs.trustwillow.com/Pig icon.png",
    "RabbitIcon": "https://docs.trustwillow.com/Rabbit icon.png",
    "ReptilesIcon": "https://docs.trustwillow.com/Reptiles icon.png",
    "SharkIcon": "https://docs.trustwillow.com/Shark icon.png",
    "SquirrelIcon": "https://docs.trustwillow.com/Squirrel icon.png",
    "TigerIcon": "https://docs.trustwillow.com/Tiger icon.png",
    "TurtleIcon": "https://docs.trustwillow.com/Turtle icon.png",
    "WhaleIcon": "https://docs.trustwillow.com/Whale icon.png",
    "ENFJIcon": "https://docs.trustwillow.com/ENFJ icon.png",
    "ENFPIcon": "https://docs.trustwillow.com/ENFP icon.png",
    "ENTJIcon": "https://docs.trustwillow.com/ENTJ icon.png",
    "ENTPIcon": "https://docs.trustwillow.com/ENTP icon.png",
    "ESFJIcon": "https://docs.trustwillow.com/ESFJ icon.png",
    "ESFPIcon": "https://docs.trustwillow.com/ESFP icon.png",
    "ESTJIcon": "https://docs.trustwillow.com/ESTJ icon.png",
    "ESTPIcon": "https://docs.trustwillow.com/ESTP icon.png",
    "INFJIcon": "https://docs.trustwillow.com/INFJ icon.png",
    "INFPIcon": "https://docs.trustwillow.com/INFP icon.png",
    "INTJIcon": "https://docs.trustwillow.com/INTJ icon.png",
    "INTPIcon": "https://docs.trustwillow.com/INTP icon.png",
    "ISFJIcon": "https://docs.trustwillow.com/ISFJ icon.png",
    "ISFPIcon": "https://docs.trustwillow.com/ISFP icon.png",
    "ISTJIcon": "https://docs.trustwillow.com/ISTJ icon.png",
    "ISTPIcon": "https://docs.trustwillow.com/ISTP icon.png",
    "AquariusIcon": "https://docs.trustwillow.com/Aquarius icon.png",
    "AriesIcon": "https://docs.trustwillow.com/Aries icon.png",
    "CancerIcon": "https://docs.trustwillow.com/Cancer icon.png",
    "CapricornIcon": "https://docs.trustwillow.com/Capricorn.png",
    "GeminiIcon": "https://docs.trustwillow.com/Gemini icon.png",
    "LeoIcon": "https://docs.trustwillow.com/Leo icon.png",
    "LibraIcon": "https://docs.trustwillow.com/Libra icon.png",
    "PiscesIcon": "https://docs.trustwillow.com/Pisces icon.png",
    "SagittariusIcon": "https://docs.trustwillow.com/Sagitarrius icon.png",
    "ScorpioIcon": "https://docs.trustwillow.com/Scorpio.png",
    "TaurusIcon": "https://docs.trustwillow.com/Taurus icon.png",
    "VirgoIcon": "https://docs.trustwillow.com/Virgo icon.png",
    "ActivismIcon": "https://docs.trustwillow.com/Activism.png",
    "ArtIcon": "https://docs.trustwillow.com/Art icon.png",
    "BakingIcon": "https://docs.trustwillow.com/Baking icon.png",
    "CoffeeTeaIcon": "https://docs.trustwillow.com/Coffee_tea icon.png",
    "CookingIcon": "https://docs.trustwillow.com/Cooking icon.png",
    "CraftsIcon": "https://docs.trustwillow.com/Crafts icon.png",
    "DancingIcon": "https://docs.trustwillow.com/Dancing icon.png",
    "ExerciseIcon": "https://docs.trustwillow.com/Exercise icon.png",
    "FaithSpiritualityIcon": "https://docs.trustwillow.com/faith_spirituality icon.png",
    "FriendsIcon": "https://docs.trustwillow.com/Friends icon.png",
    "GamingIcon": "https://docs.trustwillow.com/Gaming.png",
    "GardeningIcon": "https://docs.trustwillow.com/Gardening icon.png",
    "GymIcon": "https://docs.trustwillow.com/Gym icon.png",
    "KnittingSewingIcon": "https://docs.trustwillow.com/knitting_sewing icon.png",
    "MoviesTVIcon": "https://docs.trustwillow.com/Movies_TV icon.png",
    "NutritionIcon": "https://docs.trustwillow.com/Nutrition.png",
    "OutdoorsIcon": "https://docs.trustwillow.com/Outdoors icon.png",
    "PerformanceArtsIcon": "https://docs.trustwillow.com/Performance arts.png",
    "PhotographyIcon": "https://docs.trustwillow.com/Photography icon.png",
    "PuzzlesIcon": "https://docs.trustwillow.com/Puzzles icon.png",
    "ReadingIcon": "https://docs.trustwillow.com/Reading icon.png",
    "SportsIcon": "https://docs.trustwillow.com/Sports icon.png",
    "TravelIcon": "https://docs.trustwillow.com/Travel icon.png",
    "WalkingHikingIcon": "https://docs.trustwillow.com/walking_hiking icon.png",
    "WhoHasTimeForHobbiesIcon": "https://docs.trustwillow.com/Who has time for Hobbies_ icon.png",
    "WineSpiritsIcon": "https://docs.trustwillow.com/Wine_spirits icon.png",
    "videoIcon":"https://docs.trustwillow.com/Video.png",
    "surveyBackground": "https://docs.trustwillow.com/backgroundsurvey.webp",
    "surveyBackgroundOffline": "survey-background.png",
    "arrowContinue": "https://docs.trustwillow.com/arrowcontinue.webp",
    "backArrow":"https://docs.trustwillow.com/backarrowsurvey.webp",

    "firmSupportGeneral": "https://docs.trustwillow.com/firmSupportGeneral.png",
    "firmSupportFAQ": "https://docs.trustwillow.com/firmSupportFAQ.png",
    "firmSupportFeedback": "https://docs.trustwillow.com/firmSupportFeedback.png",
    "chevronLeft": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634180305/ybjyazahitqi0wi8zpmm.png",
    "chevronDown": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634180253/kk6pn5hwmysfkkdvcakt.png",
    "chevronRightDark": "https://res.cloudinary.com/blz-lilyriche/image/upload/v1634650973/avonqd9hcbtkbfsrfxoc.png",
    "firmXIcon": "https://docs.trustwillow.com/firmXIcon.png",
};